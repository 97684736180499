import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../components/layout";

const SuccessPage = ({ data }) => {
  return (
    <Layout>
      <div className="hero internal py-3">
        <div className="bgimage">
        <Container>
          <Row>
            <Col md="12">
              <h1 className="pl-4 pl-4 p-0 m-0 ml-2">Thank you</h1>
            </Col>
          </Row>
          </Container>
        </div>
      </div>

      <Container className="content">
        <Row>
        <Container>
          <Col md="5 p-4">
            <h4 className="mb-4">
              We have recieved your request &amp; one of our team will contact you shortly.
            </h4>

          </Col>
          <Col md="7 pr-4">
            
          </Col>
          </Container>
        </Row>
      </Container>
    </Layout>
  );
};

export default SuccessPage;
